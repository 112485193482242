import { State } from '../../Store';

interface ServiceData {
  [key: string]: any;
}

export interface IntegrationAppFormState extends State {
  modified: boolean,
  title_translations?: object,
  description_translations?: object,
  service_data?: ServiceData,
  status?: string,
  redirect_url?: string,
  meta?: object,
  ui_layout?: object,
  basic_settings?: object,
  mustache_api_template?: object,
  icon: string | File,
  images: string[] | File[],
}

export const initialState: IntegrationAppFormState = {
  modified: false,
  title_translations: {"en":{"title":"New Integration App"}},
  description_translations: {"en":{"body":"Placeholder","sidebar":"Placeholder","short":"Placeholder"}},
  service_data: {"edit":{"skip":true},"create":{"skip":true},"delete":{"skip":true}},
  status: 'Public',
  redirect_url: '',
  meta: {"product":"Zuora Billing","version":"1.0.0","category":"CRM","featured":false,"multiple":false},
  ui_layout: {"tabs":[]},
  basic_settings: {},
  mustache_api_template: {},
  icon: '',
  images: [],
};

// {
//   "authentication": {
//     "enabled": true,
//     "fieldGroups": [{
//       "fields": [{
//         "name": "field_name1",
//         "required": true,
//         "fieldType": "text",
//         "titleTranslations": {
//           "en": "Account ID"
//         },
//         "descriptionTranslations": {
//           "en": "A description1 goes here"
//         },
//         "placeholderTranslations": {
//           "en": "Placeholder"
//         }
//       }, {
//         "name": "field_name2",
//         "required": true,
//         "fieldType": "text",
//         "titleTranslations": {
//           "en": "Token ID"
//         },
//         "descriptionTranslations": {
//           "en": "A description2 goes here"
//         },
//         "placeholderTranslations": {
//           "en": "Placeholder"
//         }
//       }, {
//         "name": "password_field_name3",
//         "required": true,
//         "fieldType": "password",
//         "titleTranslations": {
//           "en": "Token Secret"
//         },
//         "descriptionTranslations": {
//           "en": "A description3 goes here"
//         },
//         "placeholderTranslations": {
//           "en": "Placeholder"
//         }
//       }, {
//         "name": "field_name4",
//         "required": true,
//         "fieldType": "text",
//         "titleTranslations": {
//           "en": "Client ID / Consumer Key"
//         },
//         "descriptionTranslations": {
//           "en": "A description4 goes here"
//         },
//         "placeholderTranslations": {
//           "en": "Placeholder"
//         }
//       }, {
//         "name": "password_field_name5",
//         "required": true,
//         "fieldType": "password",
//         "titleTranslations": {
//           "en": "Client Secret / Consumer Secret"
//         },
//         "descriptionTranslations": {
//           "en": "A description goes here"
//         },
//         "placeholderTranslations": {
//           "en": "Placeholder"
//         }
//       }, {
//         "name": "field_name6",
//         "required": true,
//         "fieldType": "text",
//         "titleTranslations": {
//           "en": "Instance ID URL"
//         },
//         "descriptionTranslations": {
//           "en": "A description6 goes here"
//         },
//         "placeholderTranslations": {
//           "en": "Placeholder"
//         }
//       }]
//     }],
//     "titleTranslations": {
//       "en": {
//         "tabName": "Authentication",
//         "cardHeader": "Authentication Status"
//       }
//     },
//     "descriptionTranslations": {
//       "en": {
//         "description": "A long description of the Authentication Tab."
//       }
//     }
//   }
// }
