import { Accordion, AutoThemeProvider, Button, Card, Grid, TextField, Toggle, Typography } from "@platform-ui/design-system"
import {ConfigWizard} from "./ConfigWizard";
import React, { useState } from "react"
import WorkflowButton from "./WorkflowButton";
import Connect from "../Connect/Connect";
import { Divider } from "@mui/material";

function ConnectorDetails(props: any) {
  const [configurationLink, setConfigurationLink] = useState("");
  const [solutionName, setSolutionName] = useState(props.connector.node.name ? props.connector.node.name : '');
  const [missingName, setMissingName] = useState(props.connector.node.name ? false : true);
  const [isNameStep, setIsNameStep] = useState(false);
  const [enabled, setEnabled] = useState(props.connector.node.enabled)

  const handleNameClick = event => {
    createConfigurationPopup(props.connector.node.id)
  }

  const handleNameField = event => {
    setSolutionName(event.target.value)
    if (event.target.value.length === 0) {
      setMissingName(true)
    } else {
      setMissingName(false)
    }
  }  

  function createConfigurationPopup(solutionId: string) {
    let csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    fetch(``, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      },
      body: JSON.stringify({solution_id: solutionId, name: solutionName})
    }).then(function(res){
      if (!res.ok) {
        throw new Error(res.statusText)
      }
      return res.json(); 
    }).then(response => {
      setConfigurationLink(response.url);
      setIsNameStep(false)
    })
  }

  function toggleInstanceEnabled(enabled: boolean) {
    let csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    fetch(window.location.href + '/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      },
      body: JSON.stringify({enabled: enabled})
    }).then(function(res){
      if (!res.ok) {
        throw new Error(res.statusText)
      }
      return res.json(); 
    }).then(response => {
      setEnabled(enabled)
    }).catch((error) => {
      Connect.log(error);
    })
  }

  let description = ''
  let name = ''
  let instanceName = ''
  let title = ''
  let nameDisabled = false
  if (props.connector.node.solution) {
    description = props.connector.node.solution.description ? props.connector.node.solution.description : ""
    name = props.connector.node.solution.title
    instanceName = props.connector.node.name
    title = instanceName
    nameDisabled = true
  } else {
    description = props.connector.node.description ? props.connector.node.description : ""
    name = props.connector.node.title
    title = name
  }
  let imagePath = Connect.setImagePath(name)
  let zuoraImageStyling = {'marginTop':'125px','marginBottom':'125px'}

  return (
    <AutoThemeProvider>
      {!(configurationLink) && !isNameStep && 
        <Grid container>
          <Grid item xs={12}>
            <Card 
              id={name}
              body={
                <Grid container>
                  <Grid item xs={6}>
                    <div style={{float:'left', height:'inherit'}}>
                      <img src={imagePath} height="50%" width="100%" style={(imagePath.includes('/Zuora-logo.png') ? zuoraImageStyling : {})}/>
                    </div>
                  </Grid>
                  <Divider orientation="vertical" flexItem style={{marginTop:'16px', marginRight:'32px', marginLeft:'32px'}}/>
                  <Grid item xs={5}>
                    <h3>{title}</h3>
                    <Typography variant={"body1"} body={description} />
                      <Divider variant="middle" style={{marginTop:'20px', marginBottom:'20px', marginLeft:'0'}} />
                      <Grid container>
                        <Grid item sx={{marginRight: '24px'}}><Button dsOnClick={() => {setIsNameStep(true)}} children="Configure" /></Grid>
                        {enabled !== undefined && <Grid item><Toggle label={"Enable"} dsOnChange={() => {toggleInstanceEnabled(!enabled)}} checked={enabled}/></Grid>}
                      </Grid>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid item xs={12}>
            {props.connector.node.workflows !== undefined && <Accordion header="Workflows" body={
              <Grid container>
                {props.connector.node.workflows.edges.map((element, i) => {
                  if (element.node.triggerUrl) {
                    return (
                      <Grid item><WorkflowButton workflow={element.node}/></Grid>
                    )
                  }
                })}
              </Grid>
            } />}
          </Grid>
        </Grid>
      }
      {!(configurationLink) && isNameStep && 
        <Card id={"configuration"} body={
          <Grid container alignContent="end" >
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6} sx={{textAlign:"end"}}><TextField label="Solution Name" dsOnChange={handleNameField} value={solutionName} placeholder="Name" disabled={nameDisabled}/></Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6} sx={{textAlign:"start", marginTop:"auto",paddingBottom:"3px"}}><Button children="Next" disabled={missingName} dsOnClick={handleNameClick}/></Grid>
          </Grid>}
        />
      }
      {configurationLink && !isNameStep && <ConfigWizard src={configurationLink} />}
    </AutoThemeProvider>
  )
}

export default ConnectorDetails